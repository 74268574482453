//this is an extension of library
import React from "react";
import { useLocation } from "react-router-dom";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";


export default function EnhancedBookDetails() {
  const location = useLocation();
  const { bookID, storyTitle, formattedStory, imageNum, legends } = location.state;  
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const { user } = UseUserAuth();
  const halfBookId = bookID;
  //console.log("bookdetails", imageNum, halfBookId);
  const imageNumArray = imageNum.split(",");
  // Convert formatted story back to paragraphs array
  const paragraphs = formattedStory
  .split('\n\n')
  .map(para => para.replace(/^\d+\.\s*/, '').trim());

  if (!Array.isArray(imageNumArray)) {
    // Handle the case where imageNumArray is not an array
    console.error("imageNumArray is not an array:", imageNumArray);
    // For example, you might want to assign a default value or throw an error
  }

  function getFullBookId(imageNumArray, halfBookId) {
    const fullBookId = imageNumArray.map((num) => `${halfBookId}_${num}`);
    return fullBookId;}

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const imagesIdArray = getFullBookId(imageNumArray, halfBookId);
                console.log("bookId-enhancedbookDetail", imagesIdArray)
                const idToken = await user.getIdToken();
                const fetchedImages = [];

                // Fetch images only
                for (let i = 1; i <=10; i++) {
                    console.log("bookidnum-enhancedbookDetail", imagesIdArray[i - 1]);
                    const response = await fetch(
                        `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: idToken,
                            },
                            body: JSON.stringify({
                                bookId: `${imagesIdArray[i - 1]}`,
                            }),
                        }
                    );

                    if (response.ok) {
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        fetchedImages.push(url);
                    } else if (response.status === 404) {
                        console.warn(`Image ${i} not ready yet. Retrying in 30 seconds...`);
                        await new Promise(resolve => setTimeout(resolve, 30000));
                        i--;
                        continue;
                    } else {
                        throw new Error(`Failed to fetch image ${i}`);
                    }
                }

                setImages(fetchedImages);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching images:", error);
                setLoading(false);
            }
        }

        fetchData();

        // Cleanup function
        return () => {
            images.forEach(url => {
                if (typeof url === 'string' && url.startsWith('blob:')) {
                    URL.revokeObjectURL(url);
                }
            });
        };
    }, [bookID, user, paragraphs.length]);

    // Create combined data structure - simpler version
    const combinedData = paragraphs.map((paragraph, index) => ({
        story: paragraph,
        image: images[index],
        legend: legends[index] || ''
    }));

// Keep everything same until return statement

return (
    <div>
        <PageNav />
        {loading ? (
            <div className="flex justify-center items-center h-screen">
                <p>Loading images...</p>
            </div>
        ) : (
            <div className="flex justify-between">
                <div className="mx-auto bg-white px-6 py-10 lg:px-8">
                    <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                        {storyTitle && (
                            <p className="mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
                                {storyTitle}
                            </p>
                        )}
                        {combinedData.map((item, index) => (
                            <div className="mt-10 max-w-2xl" key={index}>
                                <p className="font-medium text-gray-700 text-justify">
                                    {item.story}
                                </p>
                                {item.image && (
                                    <div className="text-center">
                                        <img
                                            className="mx-auto mt-4 mb-4"
                                            src={item.image}
                                            alt={`Generated Image ${index + 1}`}
                                            width="400"
                                            height="400"
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )}
    </div>
);
}