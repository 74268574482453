import { XMarkIcon } from '@heroicons/react/20/solid'
import { useState } from 'react';
import { NavLink } from "react-router-dom";

export default function Banner() {
    const [open, setOpen] = useState(true)
    if(!open){
        return null;
    }
  return (
    <div className="sticky top-20 z-40 flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
       
          <strong className="font-semibold"></strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            
          </svg>
          The product is not yet available for purchase in your region. Check out our free story gallery in the meantime!
       
      </p>

     
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={() => {setOpen(false)}}>
          <span className="sr-only">Dismiss</span>
          <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

//     <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
