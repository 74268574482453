import { Cog6ToothIcon } from "@heroicons/react/20/solid";

export default function CreationInstructions() {
 return (
   <div className="flex justify-center bg-white px-6 py-16 lg:px-8">
     <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
       <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
         The Story Creation Process
       </h1>
       <div className="mt-10 max-w-2xl">
         <ul className="list-disc mt-8 max-w-2xl space-y-8 text-gray-600">
           <li className="flex gap-x-3">
             <Cog6ToothIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
             <span>
               Purchase credits and choose your story type (Essential/Enhanced).
             </span>
           </li>
           <li className="flex gap-x-3">
             <Cog6ToothIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
             <span>
               Share your character details and story preferences.
             </span>
           </li>
           <li className="flex gap-x-3">
             <Cog6ToothIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
             <span>
               For Essential stories, review AI-generated content with 3 image revision options. For Enhanced stories, edit your story content and customize images with up to 20 revisions.
             </span>
           </li>
           <li className="flex gap-x-3">
             <Cog6ToothIcon className="mt-1 h-5 w-10 flex-none text-indigo-600" aria-hidden="true" />
             <span>
               Download your storybook or read it online anytime.
             </span>
           </li>
         </ul>
       </div>
     </div>
   </div>
 );
}
