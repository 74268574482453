import React from "react";
import { useLocation } from "react-router-dom";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";
import ImageGenAlert from "./ImageGenAlert";
import { Hourglass } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function BookDetails() {
  const location = useLocation();
  const { book } = location.state;
  const [images, setImages] = useState([]);
  const [imageGenAlert, setImageGenAlert] = useState(false);
  const navigate = useNavigate(); // Use useNavigate


  const { user } = UseUserAuth();
  const storyTitle = book[0][0];
  const imageNum = book[1][0]; //this is a string and has to be split at ',' to get the digits in an array
  const halfBookId = book[2][0];

  const paragraphs = book[4];
  const legends = book[5];
  let imageNumArray;

  if (imageNum === 'None'){ 
    imageNumArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']   
}else{
    imageNumArray = imageNum.split(",");
}
 const counterFromBackend = parseInt(book[6][0]);
 const [counter, setCounter] = useState(parseInt(counterFromBackend));
  const loraList = book[7];
  const promptList = book[8];
  const [reDidImages, setReDidImages] = useState({});
  const [selectedImage, setSelectedImage] = useState({});
  const [indexList, setIndexList] = useState(new Set());
  const [buildBook, setBuildBook] = useState(false);
  const [builtBookMessageVisible, setBuiltBookMessageVisible] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [buildingBook, setBuildingBook] = useState(false);
  const originalBookId = halfBookId;


  if (!Array.isArray(imageNumArray)) {
    // Handle the case where imageNumArray is not an array
    console.error("imageNumArray is not an array:", imageNumArray);
    // For example, you might want to assign a default value or throw an error
  }

  function getFullBookId(imageNumArray, halfBookId) {
    const fullBookId = imageNumArray.map((num) => `${halfBookId}_${num}`);
    return fullBookId;
  }


  async function pollBackendForReDidImage(bookId) {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ bookId: bookId }),
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        let url;
        await new Promise((resolve) => {
          reader.addEventListener(
            "load",
            () => {
              url = reader.result;
              resolve();
            },
            false
          );
        });

        const regex = /_(\d+)_([^_]+)$/; // Matches a number between the last two underscores
        const match = bookId.match(regex);

        let index = 0;

        if (match && match.length >= 3) {
          const secondLastNumber = match[1]; // Extract the number from the first capturing group
          index = parseInt(secondLastNumber) - 1;
        } else {
          console.error("Second last number not found in the user query.");
        }


        setReDidImages((prevReDidImages) => ({
          ...prevReDidImages,
          [index]: prevReDidImages[index]
            ? [...prevReDidImages[index], { url, bookId }] //bookId here is the bookId_imageNum
            : [{ url, bookId }],
        }));
        setIsRegenerating(false);

        return response;
      } else if (response.status === 404) {
        // Log the 404 error and continue polling
        console.warn("Endpoint not ready yet. Retrying in 30 second...");
        setTimeout(() => pollBackendForReDidImage(bookId), 30000);
      } else {
        console.error(`Failed to fetch image: `, response.status);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      setTimeout(() => pollBackendForReDidImage(bookId), 30000);
    }
  }

  async function handleReDoImage(e, i) {
    e.preventDefault();
    setIsRegenerating(true);

    const idToken = await user.getIdToken();
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/user/getCounter`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({bookId: originalBookId }),
      }
    );
    
    if (!response.ok) {
      console.warn("Something wrong");
      return ;
    }
    const result = await response.json();
    console.log("res-create-pt", result);
    const counter_points = parseInt(result);
    console.log("counter_points", counter_points);
    if (counter_points >= 3) {
      setImageGenAlert(true);
      setIsRegenerating(false); // Ensure regenerating state is reset
      return; // Exit the function early if alert is triggered
    } else {
        // Proceed with the rest of the logic if counter points are less than 20
    setIsRegenerating(true);
      const reDoPrompt = promptList[i];
      const useLora = loraList[i];
      
      try {
        const idToken = await user.getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/user/reDoImage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: idToken,
            },
            body: JSON.stringify({
              index : i ,
              reDoPrompt: reDoPrompt,
              useLora: useLora,
              bookId: originalBookId
            }),
          }
        );

        if (!response.ok) {
          //throw new Error (response.status);
          throw new Error(`Failed to redo image: ${response.statusText}`);
          // console.log(response)
        }
        //send to the backend that redid image number + counter + bookid
        //so the user_input_query db is updated
        // Update counter after successful request
        const result = await response.json();
        const updatedCounter = result['updatedCounter'];
        const updatedBookId = result['updatedBookId'];
        setCounter(updatedCounter)

        await pollBackendForReDidImage(updatedBookId);
        // Add the index to indexList set
        setIndexList((prevIndexList) => new Set([...prevIndexList, i]));
        
      } catch (error) {
        console.error("Error redoing image:", error);
        //alert('Error redoing image');
        await new Promise((resolve) => setTimeout(resolve, 30000));
        //this should call first handleredoimage
        await handleReDoImage(e, i)
        //await pollBackendForReDidImage(updatedBookId);
        // Add the index to indexList set
        //setIndexList((prevIndexList) => new Set([...prevIndexList, i]));
      }
    } 
    //else {
 //     setImageGenAlert(true);
      //alert("No more image regenerations available.");
  //  }
  }

  async function handleSelectedImage(e, index, link, bookId) {
    e.preventDefault();
    if (!bookId){
   
      bookId = `${originalBookId}_${index+1}`
    }
   
    setSelectedImage((prevSelImages) => ({
      ...prevSelImages,
      [index]: { link, bookId },
    }));
    
    // send selected image to the backend
    
    // Remove the index from indexList set
    setIndexList((prevIndexList) => {
      const updatedIndexList = new Set(prevIndexList);
      updatedIndexList.delete(index);
      return updatedIndexList;
    });
  }

  async function handleBuildPdf(e) {
    e.preventDefault();
    setBuildBook(true);
    setBuildingBook(true);
    const userQuerySelectedImagesArray = Object.values(selectedImage).map(
      (item) => item.bookId
    );
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/createPdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({
            bookId: halfBookId,
            userQuerySelectedImagesArray: userQuerySelectedImagesArray,
            counter: counter,
            imageNumArray : imageNumArray

          }),
        }
      );
      if (!response.ok) {
        console.log("Pdf building failed");
        setBuildingBook(false);
      }
    //  console.log("Built book");
      setBuiltBookMessageVisible(true);
      setBuildingBook(false);
       // Navigate to the library page after the book is built
      navigate(`/library`); // Use navigate instead of history.push
    } catch (error) {
      console.error("Internal Server Error", error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const bookId = getFullBookId(imageNumArray, halfBookId);
      //  console.log("bookId", bookId)
        const fetchedImages = [];
        for (let i = 1; i <= 10; i++) {
      //    console.log("bookidnum", bookId[i-1 ]);
          //if (fetchedImagesCount >= 10) break;
          const idToken = await user.getIdToken();
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: JSON.stringify({
                bookId: `${bookId[i - 1]}`,
              }),
            }
          );

          if (response.ok) {
        //    console.log("respImageDis", response);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            let url;
            await new Promise((resolve) => {
              reader.addEventListener(
                "load",
                () => {
                  url = reader.result;
                  resolve();
                },
                false
              );
            });

        //    console.log("url", url);
            // Push paragraphs
            fetchedImages.push({ type: "story", content: paragraphs[i - 1] }); // Assuming legends array matches images count

            // Push image
            fetchedImages.push({ type: "image", url });

            // Push legend
            fetchedImages.push({ type: "legend", content: legends[i - 1] }); // Assuming legends array matches images count
          } else if (response.status === 404) {
            // Log the 404 error and continue polling
            console.warn("Endpoint not ready yet. Retrying in 30 second...");
            await new Promise((resolve) => setTimeout(resolve, 30000));
            i--; // Retry fetching the same image
          } else {
            console.error(`Failed to fetch image: ${i}`, response.status);
          }
        }

        setImages(fetchedImages);
      } catch (error) {
        console.error("Error fetching images:", error);
        await new Promise((resolve) => setTimeout(resolve, 30000));
        fetchData(); // Retry fetching images
      }
    }

    fetchData();

    // Clean up
    return () => {
      images.forEach((item) => {
        if (item.type === "image") {
          URL.revokeObjectURL(item.url);
        }
      });
    };
  }, [user]);//was book here before

  // Combine data as suggested
  const combinedData = [];
  for (let i = 0; i < images.length; i += 3) {
    combinedData.push({
      story: images[i].content,
      image: images[i + 1].url,
      legend: images[i + 2].content,
    });
  }



  return (
    <div>
        <PageNav className="absolute inset-x-0 top-0 z-50" />
  
      {(isRegenerating && !imageGenAlert) && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 bg-gray-800 z-50">            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
                <p className="mt-4 text-white text-lg text-center">Recreating the image. This usually takes about 30 seconds. Thank you for waiting!</p>
          </div>)}
          {(buildingBook) && (
        <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 bg-gray-800 z-50">            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
                <p className="mt-4 text-white text-lg text-center">Almost there! Your book will be ready in a jiffy! Thanks for your patience!</p>
          </div>)}
    <div className="flex justify-between">
      <div className="mx-auto bg-white px-6 py-10 lg:px-8">
        {!buildBook && (
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 ">
            <div className="mx-auto text-base font-semibold leading-7 text-gray-900">{`Number of image regenerations left: ${
              3 - counter
            }`}</div>
            {storyTitle && (
              <p className=" mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
                {storyTitle}
              </p>
            )}
            {combinedData.map((item, index) => (
              <div className="mt-10 max-w-2xl" key={index}>
                {item.story && (
                  <p className="font-medium text-gray-700 text-justify">
                    {item.story}
                  </p>
                )}
                {selectedImage[index] ? ( // Check if index exists in selectedImage dictionary
                  <div className="text-center">
                    {" "}
                    <img
                      className="mx-auto mt-4 mb-4"
                      src={selectedImage[index].link}
                      alt={`Selected Image ${index}`}
                      width="400"
                      height="400"
                    />
           {/*         <p className=" text-sm font-semibold text-gray-900 justify-center mt-4 mb-4">
                      {item.legend}
                    </p> */}
                  </div>
                ) : (
                  <div className="text-center">
                    {item.image && (
                      <img
                        className="mx-auto mt-4 mb-4"
                        src={item.image}
                        alt={`Generated Image ${index}`}
                        width="400"
                        height="400"
                      />
                    )}
                    {item.legend && (
                      <>
                     {/*   <p className=" text-sm font-semibold  text-gray-900 justify-center mt-4 mb-4">
                          {item.legend}
                        </p> */}
                      

                        {reDidImages[index] && reDidImages[index].length > 0 ? ( // Conditionally render select button if at least one redo image is available
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                            onClick={(e) => {
                              handleSelectedImage(e, index, item.image);
                            }}
                          >
                            Select
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                            onClick={(e) => {
                              
                              handleReDoImage(e, index);
                            }}
                          >
                            Redo Image
                          </button>
                        )}
                      </>
                    )}
                    {reDidImages[index] && (
                      <div className="mt-10 max-w-2xl">
                        <p className="font-medium text-gray-900 text-justify">
                          Redone Image :
                        </p>
                        {reDidImages[index].map((redidImage, redidIndex) => (
                          <div className="text-center" key={redidIndex}>
                            <img
                              className="mx-auto mt-4 mb-4"
                              src={redidImage.url}
                              alt={`Redone Image ${redidIndex}`}
                              width="400"
                              height="400"
                            />
                          

                            <button
                              type="button"
                              className="inline-flex items-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-100"
                              onClick={(e) => {
                              
                                handleReDoImage(e, index);
                              }}
                            >
                              Redo Image
                            </button>
                            <button
                              type="button"
                              className="inline-flex items-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                              onClick={(e) => {
                                handleSelectedImage(
                                  e,
                                  index,
                                  redidImage.url,
                                  redidImage.bookId
                                );
                              }}
                            >
                              Select
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-end">
          {" "}
         
          {indexList.size === 0 && !buildBook && (
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 justify-end"
              onClick={handleBuildPdf}
            >
              Build my book!
            </button>
          )}{" "}
        </div>
      </div>

      {builtBookMessageVisible && (
        <div>
          <p className="mt-4 mb-4 text-2xl font-bold text-gray-900">
            Your book is built! Check your library!
          </p>
        </div>
      )}
    </div>
    {imageGenAlert && (
        <ImageGenAlert
          onConfirm={() => setImageGenAlert(false)} // Just close the dialog on confirm
        />
      )}
    </div>
  );
}