import { UseUserAuth } from "./context/UseAuthContext";
import PageNav from "./components/PageNav";
import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import CountryAlert from "./CountryAlert";
import app from "./context/Firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
//import Banner from './Banner'; 

export default function Cart() {
  const { user } = UseUserAuth();
  //const navigate = useNavigate();

  //const { productId } = useParams();
  const productId = parseInt(useLocation().state?.productId); // Access state if needed
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [countryAlert, setCountryAlert] = useState(false);
 // const [showBanner, setShowBanner] = useState(false);

  console.log("productId", { productId });
  console.log("cart prodid",typeof productId);
  console.log("cart quantity", quantity);

  useEffect(() => {
    if (productId) {
      const fetchProductInfo = async () => {
        try {
          const idToken = await user.getIdToken();
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/user/itemInfo`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: JSON.stringify({ productId: productId }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            //console.log("item", data);
            setProduct(data);
          }
         // console.log("setproduct", product);
        } catch (error) {
          console.error("Error fetching product information:", error);
        }
      };

      fetchProductInfo();
    }
  }, [productId]);

  async function handleRemove(e) {
    e.preventDefault();
   // console.log('remove', product)
    setProduct(null);
  }

  async function handleCheckout(e) {
    e.preventDefault();
    try {
      const analytics = getAnalytics(app);
      const currency = "USD";
      let item_name;
      let value ; 
      let price;
      const item_id = String(productId);
      if (item_id === '1'){
        item_name = "Essential" ;
        value = 2.50 * quantity;
        price = 2.50;
      }
      else{
        item_name = "Enhanced" ;
        value = 5 * quantity ;
        price = 5;
      }
      const items = [{item_id, item_name, price, quantity}]
      const checkout_list = {currency, value, items}
      logEvent(analytics, "begin_checkout", checkout_list);
      console.log("begin_checkout", checkout_list)
    //  console.log("checkout", productId, quantity)
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/checkoutSession`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ productId: productId, quantity: quantity }),
        }
      );

      const data = await response.json();
      if (data.status === 403){
        setCountryAlert(true);
       // alert('We are sorry. Currently, the beta launch is not available in your country. We hope to see you very soon!')
      }
      else {
      window.location.href = data; 
      }
    } catch (error) {
      console.error("Error fetching checkout information:", error);
    }
  }

  if (user.emailVerified) {
    if (productId) {
      //then we call the backend for info for this product id
      return (
        <div>
          <PageNav className="absolute inset-x-0 top-0 z-50" />
          <Heading />
          {!product ? (
            <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
              <ul
                role="list"
                className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
              >
                <li className="flex gap-x-3">
                  <ExclamationCircleIcon
                    className="mt-1 h-5 w-10 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      No Product Selected:{" "}
                    </strong>
                    Please choose a product before proceeding to checkout.
                    Browse products{" "}
                    <NavLink
                      to={`/?section=pricing`}
                      className="font-semibold text-gray-700 hover:text-gray-500 "
                    >
                      {" "}
                      here{" "}
                    </NavLink>
                    .
                  </span>
                </li>
              </ul>
            </div> // or render a placeholder indicating that the cart is empty
          ) : (
            <>
              <main>
                <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:px-0">
                  <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Shopping Cart
                  </h1>

                  <form className="mt-12">
                    <section aria-labelledby="cart-heading">
                      <h2 id="cart-heading" className="sr-only">
                        Items in your shopping cart
                      </h2>

                      <ul
                        role="list"
                        className="divide-y divide-gray-200 border-b border-t border-gray-200"
                      >
                        <li key={product.id} className="flex py-6">
                          <div className="flex-shrink-0">
                            <img
                              src={product.imageSrc}
                              alt={product.imageAlt}
                              className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
                            />
                          </div>

                          <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                            <div>
                              <div className="flex justify-between">
                                <h4 className="text-sm">
                                  <a
                                    href={product.name}
                                    className="font-medium text-gray-700 hover:text-gray-800"
                                  >
                                    {product.name}
                                  </a>
                                </h4>
                                <label
                                    htmlFor={`quantity`}
                                    className="text-sm font-medium text-gray-700 mr-2"
                                  >
                                    Quantity
                                  </label>
                                {/*}  <div className="mt-4 sm:mt-0 sm:pr-9"> 
                                <label className="sr-only">Quantity</label>*/}
                                <select
                                  id={`quantity`}
                                  name={`quantity`}
                                  value={quantity}
                                  onChange={(e) => setQuantity(e.target.value)}
                                  className="max-w-full rounded-md border border-gray-300 py-1.5 text-left text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                </select>
                                <p className="ml-4 text-sm font-medium text-gray-900">
                                  ${product.price / 100}
                                </p>
                              </div>
                            </div>
                            <div className="mt-4 flex flex-1 items-end justify-end">
                              <div className="ml-4">
                                <button
                                  type="button"
                                  className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                  onClick={(e) => handleRemove(e)}
                                >
                                  <span>Remove</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </section>

                    <section
                      aria-labelledby="summary-heading"
                      className="mt-10"
                    >
                      <h2 id="summary-heading" className="sr-only">
                        Order summary
                      </h2>

                      <div>
                        <dl className="space-y-4">
                          <div className="flex items-center justify-between">
                            <dt className="text-base font-medium text-gray-900">
                              Subtotal
                            </dt>
                            <dd className="ml-4 text-base font-medium text-gray-900">
                              ${(product.price / 100)*quantity}
                            </dd>
                          </div>
                        </dl>
                        <p className="mt-1 text-sm text-gray-500">
                          Taxes will be calculated at checkout.
                        </p>
                      </div>

                      <div className="mt-10">
                        <button
                          type="submit"
                          className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                          onClick={(e) => handleCheckout(e)}
                        >
                          Checkout
                        </button>
                      </div>
                    </section>
                  </form>
                </div>
              </main>

              <footer
                aria-labelledby="footer-heading"
                className="bg-gray-50"
              ></footer>
                {countryAlert && (
        <CountryAlert
          onConfirm={() => setCountryAlert(false)} // Just close the dialog on confirm
        />
      )}
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <PageNav className="absolute inset-x-0 top-0 z-50" />
          <Heading />
          <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
            <ul
              role="list"
              className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
            >
              <li className="flex gap-x-3">
                <ExclamationCircleIcon
                  className="mt-1 h-5 w-10 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-900">
                    No Product Selected:{" "}
                  </strong>
                  Please choose a product before proceeding to checkout. Browse
                  products{" "}
                  <NavLink
                    to={`/?section=pricing`}
                    className="font-semibold text-gray-700 hover:text-gray-500"
                  >
                    {" "}
                    here{" "}
                  </NavLink>
                  .
                </span>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading />
      <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
        <ul
          role="list"
          className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
        >
          <li className="flex gap-x-3">
            <ExclamationCircleIcon
              className="mt-1 h-5 w-10 flex-none text-indigo-600"
              aria-hidden="true"
            />
            <span>
              Please verify your email address first. If you have verified your
              email, please refresh this page.
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
     // body: JSON.stringify({ userId: user.uid }), // Assuming `user.uid` is the user ID
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }

    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};

useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}
