import React, { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";
import PageNav from "./components/PageNav";
import { useNavigate } from "react-router-dom";
import { generateSlug } from './utils';

export default function Order() {
  const { user } = UseUserAuth();
 // console.log("user lib", user)
  //console.log("token",user.getIdToken() )
  const [library, setLibrary] = useState([]);
  const [thumbNailImages, setThumbNailImages] = useState([]);
  const navigate = useNavigate();
  //const hasFetchedLibrary = useRef(false); // Ref to track if data has been fetched

  useEffect(() => {
    // console.log('useEffect triggered with user:', user);
    // Fetch library data when the component mounts
    async function fetchLibrary() {
      try {
        const idToken = await user.getIdToken();
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/user/createLibrary`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: idToken,
            },
            body: JSON.stringify(),
          }
        );
        if (!response.ok) {
          console.log("Something wrong");
          return;
        }
        const result = await response.json();
        setLibrary(result);
       // console.log(result); // Update state with fetched library data

        //iterate through library array and get bookid
        //then send imageOutput a request to fetch that image1 for bookid
        //then make the url and store in an imageArray
        try {
          const topImages = [];
          for (const item of result) {
            const bookNum = item[0];
            //const idToken = await user.getIdToken();
            const response2 = await fetch(
              `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: idToken,
                },
                body: JSON.stringify({
                  bookId: `${bookNum}_${1}`,
                }),
              }
            );
            if (response.ok) {
          //    console.log("respImageDis", response);
              const blob = await response2.blob();
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              let url;
              await new Promise((resolve) => {
                reader.addEventListener(
                  "load",
                  () => {
                    url = reader.result;
                    resolve();
                  },
                  false
                );
              });
         //     console.log("url", url);
              topImages.push(url); // Assuming legends array matches images count ]
            }
          }
          setThumbNailImages(topImages);
        } catch (error) {
          console.error("Error", error);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }

    fetchLibrary(); // Call the fetchLibrary function when the component mounts
  }, [user]); // Depend on the user object

  async function handleDownloadPdf(e, bookId) {
    e.preventDefault();

    try {
    //  console.log("book", bookId);
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getPdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ bookId: bookId }),
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `MyTaleMaker-${bookId}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Error downloading PDF");
      }
    } catch (error) {
      console.error("Error in downloading Pdf", error);
    }
  }

  async function handleReadBook(e, bookId) {
    e.preventDefault();
    try {
    //  console.log("book-read", bookId);
      const idToken = await user.getIdToken();

      // First check story type
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/checkStoryType`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
            },
            body: JSON.stringify({ bookId: bookId }),
        }
    );
    if (resp.ok) {
      const data = await resp.json();
      const storyType = data.storyType;

      if (storyType === 'Essential' || !storyType ) {

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getBook`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ bookId: bookId }),
        }
      );
      if (response.ok) {
        const bookData = await response.json();
        const slug = generateSlug(bookData[0][0]);
        //console.log("lib, bookdata", bookData);
        navigate(`/library/${slug}`, { state: { book: bookData } });
      } else {
        console.error("Error sending book");
      } }  else if (storyType === 'Enhanced'){
                // Handle Enhanced type
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND}/user/getlegends`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: idToken,
                        },
                        body: JSON.stringify({ bookId: bookId }),
                    }
                );
                if (response.ok) {
                    const bookData = await response.json();
                    const slug = generateSlug(bookData[1][0]);
                    console.log("lib-imageNum", bookData[7][0])
                    navigate(`/library/enhanced/${slug}`, { 
                        state: {
                            bookID: bookId,
                            storyTitle: bookData[1][0],
                            formattedStory: bookData[6][0],
                            imageNum: bookData[7][0],
                            legends:bookData[3]
                        }
                    });
                } else {
                    console.error("Error sending book");
                }
            }
        }
    } catch (error) {
        console.error("Error in sending book", error);
    }
    } 
  
  // in case, editing was not done before, we can edit here
  //this will be only available if generatedPdf is null and generatedimage and generatedstory are 1
  //display edit button then
  async function handleEditBook(e, bookId) {
    e.preventDefault();
    try {
        const idToken = await user.getIdToken();
        
        // First check story type
        const resp = await fetch(
            `${process.env.REACT_APP_BACKEND}/user/checkStoryType`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: idToken,
                },
                body: JSON.stringify({ bookId: bookId }),
            }
        );

        if (resp.ok) {
            const data = await resp.json();
            const storyType = data.storyType;

            if (storyType === 'Essential'|| !storyType) {
                // Handle Essential type
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND}/user/getBook`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: idToken,
                        },
                        body: JSON.stringify({ bookId: bookId }),
                    }
                );
                if (response.ok) {
                    const bookData = await response.json();
                    const slug = generateSlug(bookData[0][0]);
                    navigate(`/library/${slug}/edit`, { 
                        state: { book: bookData } 
                    });
                } else {
                    console.error("Error sending book");
                }
            }else if (storyType === 'Enhanced') {
                // Handle Enhanced type
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND}/user/getlegends`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: idToken,
                        },
                        body: JSON.stringify({ bookId: bookId }),
                    }
                );
                if (response.ok) {
                    const bookData = await response.json();
                    //const bookID = bookData[0][0];
                    //const storyTitle = bookData[1][0];
                    // Convert paragraphs string back to array and clean up
                    //const formattedStory = bookData[6][0]
                    const slug = generateSlug(bookData[1][0]);
                    navigate(`/library/enhanced/${slug}/edit`, { 
                        state: {
                            bookID: bookId,
                            storyTitle: bookData[1][0],
                            formattedStory: bookData[6][0],
                            imageNum: bookData[7][0],
                        }
                    });
                } else {
                    console.error("Error sending book");
                }
            }
        }
    } catch (error) {
        console.error("Error in sending book", error);
    }
}

  function Getdate(bookId) {
    // Convert the timestamp to a Date object
    const date = new Date(parseInt(bookId));

    // Get individual date components
    const year = date.getFullYear();
    const day = date.getDate();

    // Get month name
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];

    // Format the date and time
    const formattedDateTime = `${month} ${day}, ${year}`;
    return formattedDateTime;
  }
  //function LibraryDisplay({ library, thumbNailImages }) {
  function LibraryDisplay() {
    // Combine each item in the library array with the corresponding URL
    const combinedLibrary = library.map((item, index) => {
      const bookId = item[0];
      const title = item[1];
      const generatedPdf = item[2]; 
      const url = thumbNailImages[index]; // Get the corresponding URL using index
      const createdAt = Getdate(bookId); // Get createdAt using bookId
      return { bookId, title,generatedPdf, url, createdAt };
    });
  //  console.log("comb", combinedLibrary);
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24">
          <div className="max-w-xl">
            <h1
              id="your-orders-heading"
              className="text-3xl font-bold tracking-tight text-gray-900"
            >
              Your Library
            </h1>
            <p className="mt-2 text-sm text-gray-500">
              Read you own creations here!
            </p>
          </div>

          <div className="mt-12 space-y-16 sm:mt-16">
            {combinedLibrary.map((item, index) => (
              <section
                key={item.bookId}
                aria-labelledby={`${item.bookId}-heading`}
              >
                <div className="space-y-1 md:flex md:items-baseline md:space-x-4 md:space-y-0"></div>
                <div className="-mb-6 mt-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
                  <div key={index} className="py-6 sm:flex">
                    <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                      <img
                        src={item.url}
                        className="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-48 sm:w-48"
                        alt=""
                      />
                      <div className="min-w-0 flex-1 pt-1.5 sm:pt-0">
                        <p className="mt-1 font-medium text-gray-900">
                          {" "}
                          {item.title}
                        </p>
                        <span
                          className="mt-1 text-sm text-gray-700"
                          aria-hidden="true"
                        >
                          Created On: {item.createdAt}
                        </span>
                        <h3 className="mt-1 text-sm font-medium text-gray-800">
                          <a href={item.title}>Order# {item.bookId}</a>
                        </h3>
                      </div>
                    </div>
                    <div className="mt-6 space-y-4 sm:ml-6 sm:mt-0 sm:w-40 sm:flex-none">
                      {item.generatedPdf === 1 && (
                    <button
                        type="button"
                        onClick={(e) => {
                          handleReadBook(e, item.bookId);
                        }}
                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-2.5 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0"
                      >
                        Read
                      </button>)}
                    {item.generatedPdf === 1 && (
                      <button
                        type="button"
                        onClick={(e) => {
                          handleDownloadPdf(e, item.bookId);
                        }}
                        className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0"                      >
                        Download PDF
                      </button> )}
                      {item.generatedPdf === 0 && (
                      <button
                        type="button"
                        onClick={(e) => {
                          handleEditBook(e, item.bookId);
                        }}
                        className="flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0"
                      >
                        Edit
                      </button>)}
                   
                      
                    </div>
                  </div>
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      {library.length > 0 ? (
        <LibraryDisplay />
      ) : (
        <div className="bg-white">
          <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24">
            <div className="max-w-xl">
              <h1
                id="your-orders-heading"
                className="text-3xl font-bold tracking-tight text-gray-900"
              >
                Your Library
              </h1>
              <p className="mt-2 text-sm text-gray-500">
                Your creations will be stored here!
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
