/* eslint-disable react/no-unescaped-entities */
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";
import { CheckCircleIcon, RocketLaunchIcon } from "@heroicons/react/20/solid";
import AlertCredit from "./AlertCredit";
import Modal from "./Modal";
import InputConfirmation from "./UserInputDialogueBox";
import MissingInput from "./MissingInputDialogueBox";
import { Hourglass } from 'react-loader-spinner';
import { generateSlug } from './utils';
import { useNavigate } from "react-router-dom";

function CreateEnhanced() {
  const [bookId, setBookId] = useState(null); // Define userQueryID state
  const [story, setStory] = useState("");
  const [paragraphs, setParagraphs] = useState("");
  const [storyTitle, setStoryTitle] = useState("");
  const { user } = UseUserAuth();
  const [isSaved, setIsSaved] = useState(false); // Track save status at parent level

  const navigate = useNavigate();

  console.log("story top", story)

  // Create a Message component
const Message = ({ message, type, onClose }) => {
  return (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 p-4 rounded-md shadow-lg ${
      type === 'error' ? 'bg-red-50 text-red-800' : 
      type === 'success' ? 'bg-green-50 text-green-800' : 
      'bg-blue-50 text-blue-800'
    }`}>
      <div className="flex items-center">
        <p className="text-sm font-medium">{message}</p>
        <button 
          onClick={onClose}
          className="ml-4 text-gray-400 hover:text-gray-500"
        >
          ×
        </button>
      </div>
    </div>
  );
};

  // In your main component, add state for the message
const [message, setMessage] = useState(null);

  async function handleSave() {
    if (!bookId) {
      alert("No story ID found. Please generate a story first.");
      return false;
    }

    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/saveStory`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: idToken,
        },
        body: JSON.stringify({
          bookId,
          paragraphs,
          storyTitle
        }),
      });
      
      if (response.ok) {
        setIsSaved(true); // Set save state to true after successful save
        //alert("Story saved successfully!");
        const resp = await response.json();
        const storyTitle = resp['storyTitle']
        const slug = generateSlug(storyTitle);
        const bookID = resp['bookId'];
        const formattedStory = resp['formattedStory'];
        const imageNum = 'None';
        //console.log("lib, bookdata", bookData);
        navigate(`/library/enhanced/${slug}/edit`, { state:  { 
          bookID,
          storyTitle,
          formattedStory,
          imageNum
        //  fromCreate: true // Flag to show we're coming from creation
        } });
        return true;
      } else {
        //alert("Failed to save story. Please try again.");
       // return false;

       // Handle different error status codes
    const errorText = await response.text();
    
    switch (response.status) {
      case 400:
        if (errorText.includes('title')) {
          setMessage({
            text: "Please provide a story title.",
            type: "error"
          });
        } else if (errorText.includes('paragraph')) {
          setMessage({
            text: "Please ensure all story paragraphs have content.",
            type: "error"
          });
        }
        break;


      case 403:
    /*    if (errorText.includes('inappropriate')) {
          alert(errorText);
        } else if (errorText.includes('10 paragraph')) {
          alert("Your story must contain exactly 10 paragraphs.");
        } else {
          alert("Your story content needs adjustment. Please review and try again.");
        }
        break; */
        if (errorText.includes('inappropriate word')) {
          setMessage({
            text: errorText,
            type: "error"
          });
        } else if (errorText.includes('10 paragraph')) {
          setMessage({
            text: "Your story must contain exactly 10 paragraphs.",
            type: "error"
          });
        }
        break;
        
      case 404:
        if (errorText.includes('no credit')) {
          setMessage({
            text: "You don't have enough credits. Please purchase more credits to save your story.",
            type: "error"
          });
        }
        break;


      case 500:
        alert("We're experiencing technical difficulties. Please try again later.");
        break;

      default:
        alert("Failed to save story. Please try again.");
    }
    return false;

  }
      
    } catch (error) {
      console.error("Error saving story:", error);
      alert("Error saving story. Please try again.");
      return false;
    }
  }

  function handleRewrite() {
    setStory("");
    setParagraphs("");
    setStoryTitle("");
    setBookId(null);
    setIsSaved(false);
  }

  return (
    <div>
      <PageNav className="absolute inset-x-0 top-0 z-50" />
      <Heading />
      <Generate />
      <MyForm
        setBookId={setBookId}
        setStory={setStory}
        setParagraphs={setParagraphs}
        setStoryTitle={setStoryTitle} 
      />
      <StoryEditor 
        story={story}
        setStory={setStory}
        storyTitle={storyTitle}
        setStoryTitle={setStoryTitle}
        paragraphs={paragraphs}
        setParagraphs={setParagraphs}
        onSave={handleSave}
        onRewrite={handleRewrite}
        isSaved={isSaved}
      />
      {message && (
      <Message 
        message={message.text}
        type={message.type}
        onClose={() => setMessage(null)}
      />
    )}
    </div>
  );
}
    

    function StoryEditor({ story, setStory, storyTitle, setStoryTitle, paragraphs, setParagraphs, onSave, onRewrite, isSaved }) {
    
      return (
        <div className="max-w-3xl mx-auto mt-10 mb-20">
          {/* Title Editor */}
          <div className="mt-6">
            <label htmlFor="story-title" className="block text-sm font-medium text-gray-900">
              Story Title
            </label>
            <input
              type="text"
              id="story-title"
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              value={storyTitle}
              onChange={(e) => setStoryTitle(e.target.value)}
              placeholder="Your story title will appear here"
              style={{ padding: "10px" }}
              disabled={!story || isSaved}
            />
          </div>
    
          {/* Paragraphs Editor */}
          <div className="mt-6">
            <label className="block text-sm font-medium text-gray-900">
              Story Content {paragraphs.length > 0 && `(${paragraphs.length} paragraphs)`}
            </label>
            
            {paragraphs.length > 0 ? (
              <div className="mt-2 space-y-4">
                {paragraphs.map((paragraph, index) => (
                  <div key={index} className="relative">
                    <label className="absolute left-2 top-2 text-sm font-medium text-gray-500">
                      {index + 1}.
                    </label>
                    <textarea
                      className="block w-full rounded-md border-0 py-1.5 pl-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={paragraph}
                      onChange={(e) => {
                        const newParagraphs = [...paragraphs];
                        newParagraphs[index] = e.target.value;
                        setParagraphs(newParagraphs);
                      }}
                      rows={3}
                      style={{ padding: "10px", paddingLeft: "2rem" }}
                      disabled={isSaved}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-4">
                Fill the form above and click Generate to create your story. Once generated, your story content will appear here and you can edit it if you'd like.
              </div>
            )}
          </div>
    
          {/* Action Buttons */}
          <div className="mt-6 flex gap-4 mb-10">
            <button
              className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                story && !isSaved
                  ? "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600" 
                  : "bg-indigo-300 cursor-not-allowed"
              }`}
              onClick={onSave}
              disabled={!story || isSaved}
            >
              {isSaved ? "Story Saved" : "Save Story"}
            </button>
            <button
              className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                story && !isSaved
                  ? "bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600" 
                  : "bg-gray-300 cursor-not-allowed"
              }`}
              onClick={onRewrite}
              disabled={!story || isSaved}
            >
              Rewrite Story
            </button>
          </div>
    
          {/* Success message */}
          {isSaved && (
            <div className="mt-2 text-sm text-green-600 flex items-center gap-2">
              <CheckCircleIcon className="h-5 w-5"/> 
              Story has been saved successfully. The story will now proceed for image generation.
            </div>
          )}
        </div>
      );
    }

function Heading() {
  const { user } = UseUserAuth();
  const [userName, setUserName] = useState("");
 // console.log("create user", user);

 const fetchUserName = async () => {
  try {
    const idToken = await user.getIdToken();
    const response = await fetch(`${process.env.REACT_APP_BACKEND}/user/getUserName`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: idToken,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user name");
    }
    const data = await response.json();
    setUserName(data);
  } catch (error) {
    console.error("Error fetching user name:", error);
  }
};
useEffect(() => {
  if (user) {
    fetchUserName();
  }
}, [user]);

return (
  <div className="flex justify-center text-lg font-semibold p-4 box mt-3 text-center text-indigo-600">
    Welcome <br />
    {userName || (user ? (user.displayName ? user.displayName : user.email) : "Guest")}
  </div>
);
}

function Generate() {
  return (
    <div className=" flex justify-center bg-white px-6 py-10 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center ">
          Instructions:
        </h1>{" "}
        <p className="mt-6 text-xl leading-8">
          Follow these simple steps to create your very own one-of-a-kind
          storybook
        </p>
        <div className="mt-10 max-w-2xl">
          <ul
            role="list"
            className="mt-8 max-w-2xl space-y-8 text-gray-600 text-justify"
          >
            
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Fill the boxes:{" "}
                </strong>
                Provide name of your character and select their gender. These
                details are essential for crafting your unique tale. Choose a
                moral from our list or share your own description or do both to
                add depth and meaning to your narrative. Select the reader's age group to ensure the story is in an appropriate, readable format. 
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Story Generation:{" "}
                </strong>{" "}
                After submitting the form, please wait for 30 seconds to 1 minute while we craft your story. Once generated, your story will appear in the editor below the form.
              </span>
            </li>

            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-10 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold text-gray-900">
                  Review and Edit:{" "}
                </strong>{" "}
                Once your story appears, you have three options:
                <ul className="mt-2 ml-4 space-y-2">
                  <li>• Press "Save Story" to accept the story as is and proceed to image creation</li>
                  <li>• Make minor edits to the title or story content before pressing "Save Story"</li>
                  <li>• If you're not satisfied with the story, press "Rewrite Story" to return to the form and generate a new version</li>
                </ul>
              </span>
            </li>



          </ul>
        </div>
      </div>
    </div>
  );
}

function MyForm({
  setBookId,
  setStory,
  setParagraphs,
  setStoryTitle,
}) {
  const [gender, setGender] = useState("girl");
  const [readerAgeGroup, setReaderAgeGroup] = useState('2-4years')
  const [mainChrName, setMainChrName] = useState("");
  const [description, setDescription] = useState("");
  const [moralValue, setMoralValue] = useState("");
  const [appearanceValue, setAppearanceValue] = useState("medium_brown");
  const { user } = UseUserAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false); // Set to true initially to show the modal
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [creditPoints, setCreditPoints] = useState(0); // Initialize creditPoints state
  const [isMissingInputOpen, setIsMissingInputOpen] = useState(false);

  async function pollBackendForResult(bookId) {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/enhancedTextOutput`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify({ bookId: bookId }),
        }
      );
      if (response.status === 404) {
        console.warn("Endpoint not ready yet. Retrying in 30 second...");
        setTimeout(() => pollBackendForResult(bookId), 30000);
        return;
      }
      const jsonString = await response.text();
      console.log("resp", response);
      console.log('jsonString', jsonString);
      const resultReady = jsonString.includes(bookId);
      console.log('resultReady', resultReady);
      const data = JSON.parse(jsonString);
      const userStory = data.story;
    
      const allParagraphs = userStory
        .split("\n")
        .filter((paragraph) => paragraph.trim() !== "");
      const unclean_paragraphs = allParagraphs.slice(1);
      // Remove numbers from paragraphs
      const paragraphs = unclean_paragraphs.map((paragraph) => {
        const cleanedText = paragraph.replace(/^\d+\.\s*/, "");
        return cleanedText;
      });
     
      //Extracting title
      //const titleMatch = userStory.match(/^Title: (.+)$/m);
      //const storyTitle = titleMatch ? titleMatch[1] : "";
          // Determine the format of the story
      const isOldFormat = userStory.startsWith('Title:');
      let storyTitle = '';
      //let splits = story.split('\n').filter(paragraph => paragraph.trim() !== '');
      if (isOldFormat) {
        // Extract the title using the old format
        const titleMatch = userStory.match(/^Title: (.+)$/m);
        storyTitle = titleMatch ? titleMatch[1] : '';
        // Remove the first line (title) from the paragraphs
        //splits = splits.slice(1);
      } else {
        // Extract the title from the first line for the new format
        const splits = userStory.split('\n').filter((paragraph) => paragraph.trim() !== '');
        storyTitle = splits[0].trim();
        if (storyTitle.startsWith('**') && storyTitle.endsWith('**')) {
          storyTitle = storyTitle.replace(/\*\*/g, '').trim();
        }
        // Remove the title from the paragraphs
        //splits = splits.slice(1);
        // Remove non-alphanumeric characters from the start and end of the title
      storyTitle = storyTitle.replace(/^[^\w]+|[^\w]+$/g, '');
      console.log('paragraphs', paragraphs, 'storyTitle', storyTitle,'userStory', userStory )
      }

      if (resultReady){
        setParagraphs(paragraphs);
        setStoryTitle(storyTitle);
        setStory(userStory);
        setIsLoading(false);
      } else {
        // Continue polling every 30 seconds
        setTimeout(() => pollBackendForResult(bookId), 30000); // Poll every 30 seconds
      }
    } catch (error) {
      console.error("Error polling for result:", error);
      setTimeout(() => pollBackendForResult(bookId), 30000); // Poll every 30 seconds
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let credit_points = 0;
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/getNumCredits`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: idToken,
          },
          body: JSON.stringify(),
        }
      );
      if (!response.ok) {
        console.warn("Something wrong");
        return 0;
      }
      const result = await response.json();
      credit_points = parseInt(result);
      
    } catch (error) {
      console.error("Error", error);
      return 0;
    }
    if (credit_points < 2 || isNaN(credit_points)) {
      setAlertVisible(true);
      return;
    }
    //first make a request to backend checking the number of creadits is >=1 book,
    //if there are credits then proceed as is, otherwise throw error stating that
    //please buy credit first
    setIsConfirmationOpen(true);
    //setCreditPoints(credit_points); // Update creditPoints state
  }
  async function handleConfirm() {
    //e.preventDefault();
    const idToken = await user.getIdToken();
    setStory("");
    //setLegends("");
    setParagraphs("");
    setStoryTitle("");
   // setPrompts("");
   // setLora("");
    const storyType = 'Enhanced'
    const info = {
      gender,
      mainChrName,
      description,
      moralValue,
      appearanceValue,
      readerAgeGroup,
      storyType
    };
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: idToken },
      body: JSON.stringify(info),
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/user/create`,
        requestOptions
      );
      if (!response.ok) {
        setIsMissingInputOpen(true);
        setIsLoading(false);
        return;
      }
      setIsMissingInputOpen(false);
      const { bookId } = await response.json();
      setBookId(bookId);
      pollBackendForResult(bookId);
      setIsConfirmationOpen(false); // Moved inside try block
    } catch (error) {
      console.error("Error handling confirmation:", error);
      setIsLoading(false);
    }
  }

  function handleCancel(e) {
    e.preventDefault();
    console.log("Creation canceled.");
    setIsConfirmationOpen(false);
  }



  
  return (
    <div className="flex justify-center bg-slate-50">
      <div className="max-w-md mb-10">
        <h2 className="flex gap-x-3 mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
          <RocketLaunchIcon
            className="mt-1 h-5 w-5 flex-none text-indigo-600"
            aria-hidden="true"
          />
          <span>
            {" "}
            Ready to unleash your creativity? Fill and submit the form below.
          </span>
        </h2>
        <div className="grid justify-center bg-slate-50">
          <form onSubmit={handleSubmit}>
            <div className="space-y-5">
              <div className="border-b border-gray-900/10 pb-2"> </div>

              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Provide your input to build a story
              </h2>

              <h2 className="text-base text-md leading-7 text-gray-700">
                Story Type Selected : Enhanced 
              </h2>

              <p className="mt-1 text-sm leading-6 text-gray-600">
                Once submitted, scroll down to see your story come to life.
              </p>

              <div className=" mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name of the main character
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={mainChrName}
                      onChange={(e) => setMainChrName(e.target.value)}
                      style={{ padding: "10px" }}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <div className="mt-2">
                    <select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      id="gender"
                      name="gender"
                      autoComplete="gender"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="girl">Girl</option>
                      <option value="boy">Boy</option>
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-3 ">
                  {" "}
                  <label
                    htmlFor="moral"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Moral of the story
                  </label>
                  <div className="mt-2">
                    <select
                      value={moralValue}
                      onChange={(e) => setMoralValue(e.target.value)}
                      id="moral"
                      name="moral"
                      autoComplete="moral"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value=""></option>
                      <option value="courage">Courage</option>
                      <option value="honesty"> Honesty</option>
                      <option value="respect">Respect</option>
                      <option value="responsibility">Responsibility</option>
                      <option value="empathy">Empathy</option>
                      <option value="compassion">Compassion</option>
                      <option value="fairness">Fairness</option>
                      <option value="gratitude">Gratitude</option>
                      <option value="cooperation">Cooperation</option>
                      <option value="generosity">Generosity</option>
                      <option value="self-discipline">Self-Discipline</option>
                      <option value="perseverance">Perseverance</option>
                      <option value="humility">Humility</option>
                      <option value="tolerance">Tolerance</option>
                      <option value="patience">Patience</option>
                      <option value="kindness">Kindness</option>
                      <option value="resilience">Resilience</option>
                      <option value="forgiveness">Forgiveness</option>
                      <option value="integrity">Integrity</option>
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="appearance"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Customize your character's appearance
                  </label>
                  <div className="mt-2">
                    <select
                      value={appearanceValue}
                      onChange={(e) => setAppearanceValue(e.target.value)}
                      id="appearance"
                      name="appearance"
                      autoComplete="appearance"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      {" "}
                      <option value="fair_blond">
                        Fair Skin Tone, Blond Hair
                      </option>
                      <option value="medium_brown">
                        Medium Skin Tone, Brown Hair
                      </option>
                      <option value="dark_black">
                        Dark Skin Tone, Black Hair
                      </option>
                      <option value="warm_black">
                        Warm, Light Tan Skin Tone, Black Hair
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900" >
                    Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      id="description"
                      name="description"
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      style={{ padding: "10px" }}
                    />
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    Write a few sentences about storyline.
                  </p>
                </div>
              </div>
           

            <div className="sm:col-span-6">
                  <label
                    htmlFor="readerAgeGroup"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Reader's age group
                  </label>
                  <div className="mt-2">
                    <select
                      value={readerAgeGroup}
                      onChange={(e) => setReaderAgeGroup(e.target.value)}
                      id="readerAgeGroup"
                      name="readerAgeGroup"
                      autoComplete="readerAgeGroup"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                      <option value="2-4years">2-4 years</option>
                      <option value="5-7years">5-7 years</option>
                    </select>
                  </div>
                </div>
                <div className="border-b border-gray-900/10 pb-2">
                <div className="mt-2 space-y-2"></div>
              </div>
            </div>
            <div className="mt-2 flex items-center justify-end gap-x-6">

              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Generate
              </button>
            </div>
          </form>
             {isLoading && (
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 bg-gray-800 z-50">            
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
                <p className="mt-4 text-white text-lg text-center">Your story is being generated and typically takes around 30s. Thank you for your patience!</p>
          </div>
        )}
        
        </div>
      </div>
      <Modal isOpen={alertVisible} onClose={() => setAlertVisible(false)}>
        <AlertCredit />
      </Modal>
      {/* Show the confirmation dialog if it's open */}
      {isConfirmationOpen && (
        <InputConfirmation
          onConfirm={(e) => {handleConfirm(e); setIsConfirmationOpen(false)}}
          onCancel={(e) => {handleCancel(e);  setIsConfirmationOpen(false)}}
        />
      )}
      {isMissingInputOpen && (
        <MissingInput
          onConfirm={() => setIsMissingInputOpen(false)} // Just close the dialog on confirm
        />
      )}
    </div>
  );
}
export default CreateEnhanced;
