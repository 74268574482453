import { CheckIcon } from '@heroicons/react/20/solid';
import { UseUserAuth } from './context/UseAuthContext';
import { useNavigate } from 'react-router-dom';
const tiers = [
  {
    name: 'Essential', //'Pay-as-you-go',
    id: '1',
    href: '#tier-one-book',
    price: '$2.50',
    description: 'Perfect for Quick Personalized Stories',//'A Perfect Introduction to Personalized Adventures.',
    features: ["Create a custom children's e-book in 2 minutes",'Up to 3 image recreation per story','A complete 10-page story with illustrations', 'Download as PDF or read online'],
  },
  {
    name: 'Enhanced',//'Bundle',
    id: '2',
    href: '#tier-bundle',
    price: '$5.00',
    description: 'Greater Creative Control Over Your Story', //Greater Creative Freedom
    features: [
      "Create a custom children's e-book in a few minutes",
      'Up to 20 image customizations (edit image descriptions or regenerate image) per story', //'Try different image descriptions to explore new visual styles',
      'A complete 10-page story with illustrations', //'Refine illustrations with up to 20 image revisions',
      'Edit story text and story title to your liking',
      'Full creative control over your storybook',
      'Download as PDF or read online'
      //'Cancel anytime'
    ],
  },
  
]

export default function Pricing() {
  const {  user } = UseUserAuth();
  //console.log("create user", user);
  const navigate = useNavigate();

  function handleCheckout(e, productId) {
    e.preventDefault();
    if (user){
     console.log("productid",productId);
     console.log(typeof productId);
    navigate(`/cart`, { state: { productId : productId } }); }
    else{
      navigate('/login')
    } // Pass product ID as state
  };
  return (
    <div className="isolate overflow-hidden bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 pb-96 pt-16 text-center sm:pt-30 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Invest in your child's reading adventure  
           
          </p>
        </div>
        <div className="relative mt-6">
          <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
          Spark your child's imagination, foster a love of reading, and create a cherished memory together.
          </p>
           <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
         
          </svg>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-80">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map((tier) => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <h3 id={tier.id} className="text-base font-semibold leading-7 text-indigo-600">
                      {tier.name}
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                  
                      <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price}</span>
                      <span className="text-5xl font-bold tracking-tight text-gray-900">{}</span>
                     
                     {/*} {tier.priceMonthly === '$34.99' && <span className="text-base font-semibold leading-7 text-gray-600">/month</span>} */}
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">{tier.description}</p>
                    <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-600">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex gap-x-3">
                          <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={(e) => handleCheckout(e, tier.id)}>
                    Buy
                  </button>
                </div>
              ))}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}



