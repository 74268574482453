import React, { useEffect } from 'react';
import { XMarkIcon } from "@heroicons/react/20/solid";

const AlertMessage = ({ message, type, onClose, autoClose = 50000 }) => {
  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(() => {
        onClose();
      }, autoClose);
      return () => clearTimeout(timer);
    }
  }, [autoClose, onClose]);

  const bgColor = {
    error: 'bg-red-50 border-red-200',
    warning: 'bg-yellow-50 border-yellow-200',
    success: 'bg-green-50 border-green-200',
    info: 'bg-blue-50 border-blue-200'
  }[type];

  const textColor = {
    error: 'text-red-800',
    warning: 'text-yellow-800',
    success: 'text-green-800',
    info: 'text-blue-800'
  }[type];

  return (
    <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 z-50 p-4 rounded-md shadow-lg border ${bgColor} max-w-md w-full mx-4`}>
      <div className="flex items-start justify-between">
        <p className={`text-sm font-medium ${textColor}`}>{message}</p>
        <button 
          onClick={onClose}
          className="ml-4 inline-flex text-gray-400 hover:text-gray-500 focus:outline-none"
        >
          <XMarkIcon className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default AlertMessage;
