
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageNav from "./components/PageNav";
import { useState, useEffect } from "react";
import { UseUserAuth } from "./context/UseAuthContext";
import { Hourglass } from 'react-loader-spinner';
import ImageGenAlert from "./ImageGenAlert";
import AlertMessage from "./AlertBox"; 

export default function EnhancedBookEdit() {
    const location = useLocation();
    const { bookID, storyTitle, formattedStory, imageNum, legendsFromGetlegends } = location.state;
    const bookId = bookID;
    const [images, setImages] = useState([]);
    const [legends, setLegends] = useState([]);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reDidImages, setReDidImages] = useState({});
    const [selectedImage, setSelectedImage] = useState({});
    const [indexList, setIndexList] = useState(new Set());
    const [buildBook, setBuildBook] = useState(false);
    const [buildingBook, setBuildingBook] = useState(false);
    const [isRegenerating, setIsRegenerating] = useState(false);
    const { user } = UseUserAuth();
    const navigate = useNavigate();
    const [characterCounts, setCharacterCounts] = useState({});
    const [imageGenAlert, setImageGenAlert] = useState(false);
    const [activeTextArea, setActiveTextArea] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);

    let imageNumArray;

    if (imageNum === 'None'){ 
        imageNumArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']   
    }else{
        imageNumArray = imageNum.split(",");
    }
    // Convert formatted story back to paragraphs array
    const paragraphs = formattedStory
        .split('\n\n')
        .map(para => para.replace(/^\d+\.\s*/, '').trim());

    function getFullBookId(imageNumArray, halfBookId) {
        const fullBookId = imageNumArray.map((num) => `${halfBookId}_${num}`);
        return fullBookId;
          }
    // Create combined data structure
    const combinedData = paragraphs.map((paragraph, index) => {
        const originalLegend = legends[index] || '';
        const isRedone = reDidImages[index] ? true : false;
        const isSelected = selectedImage[index] ? true : false;

        // Include both original and redone images if they exist
        const allImages = [];

        // Original image
        if (images[index]) {
            allImages.push({
                url: images[index],
                isOriginal: true
            });
        }

        // Add redone images if they exist
        if (reDidImages[index]) {
            allImages.push(...reDidImages[index].map(redone => ({
                url: redone.url,
                bookId: redone.bookId,
                isOriginal: false
            })));
        }

        return {
            story: paragraph,
            images: allImages,
            selectedImage: selectedImage[index],
            legend: originalLegend,
            hasRedoneImages: isRedone,
            isSelected: isSelected,
            imageIndex: index
        };
    });

    async function pollBackendForReDidImage(bookId) {
        try {
            const idToken = await user.getIdToken();
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: idToken,
                    },
                    body: JSON.stringify({ bookId: bookId }),
                }
            );

            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const regex = /_(\d+)_([^_]+)$/;
                const match = bookId.match(regex);
                let index = 0;

                if (match && match.length >= 3) {
                    index = parseInt(match[1]) - 1;
                }

                setReDidImages((prevReDidImages) => ({
                    ...prevReDidImages,
                    [index]: prevReDidImages[index]
                        ? [...prevReDidImages[index], { url, bookId }]
                        : [{ url, bookId }],
                }));
                setIsRegenerating(false);
            } else if (response.status === 404) {
                console.warn("Endpoint not ready yet. Retrying in 30 second...");
                setTimeout(() => pollBackendForReDidImage(bookId), 30000);
            }
        } catch (error) {
            console.error("Error fetching redone image:", error);
            setTimeout(() => pollBackendForReDidImage(bookId), 30000);
        }
    }

    async function handleReDoImage(e, index) {
        e.preventDefault();

        // Check if legend exists and is not empty for the given index
        const legend = legends[index];
        if (!legend || legend.trim() === '') {
                setAlertMessage({
                type: 'error',
                message: 'Please provide an image description before redoing the image.'
                });
                return;
            }
        setIsRegenerating(true);

        try {
            const idToken = await user.getIdToken();
            const resp = await fetch(
                `${process.env.REACT_APP_BACKEND}/user/getCounter`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: idToken,
                  },
                  body: JSON.stringify({bookId: bookId }),
                }
              );
              if (!resp.ok) {
                setAlertMessage({
                    type: 'error',
                    message: 'Failed to check remaining image generations.'
                  });
                  setIsRegenerating(false);
                  return;
              }
              const res = await resp.json();
              console.log("res-create-pt", res);
              const counter_points = parseInt(res);
              console.log("counter_points", counter_points);
              // setCredits(parseInt(result));
              
              // if counter >=20 then send an alert
              if (counter_points>=20){
                setImageGenAlert(true);
                setIsRegenerating(false); // Ensure regenerating state is reset
                return; // Exit the function early if alert is triggered
                
              }
            const requestBody = {
                bookId: bookId,
                legend: legend.trim(),    // Send the trimmed legend
                legendIndex: index,       // Send the index of the legend
              };

            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/user/reDoEnhancedImage`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: idToken,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                const errorText = await response.text();
                
                switch (response.status) {
                  case 400:
                    setAlertMessage({
                      type: 'warning',
                      message: errorText
                    });
                    break;
                  
                  case 403:
                    setAlertMessage({
                      type: 'error',
                      message: errorText
                    });
                    break;
          
                  case 404:
                    setAlertMessage({
                      type: 'error',
                      message: 'Required data not found. Please try again.'
                    });
                    break;
          
                  default:
                    throw new Error(`Failed to redo image: ${response.statusText}`);
                }
                setIsRegenerating(false);
                return;
              }

            const result = await response.json();
            setCounter(result.updatedCounter);
            await pollBackendForReDidImage(result.updatedBookId);
            setIndexList((prevIndexList) => new Set([...prevIndexList, index]));
        } catch (error) {
            console.error("Error redoing image:", error);
            setAlertMessage({
              type: 'error',
              message: 'Failed to generate image. Please try again.'
            });
            setIsRegenerating(false);
          }
    }

    async function handleSelectedImage(e, index, link, imageIdFromButton) { //bookId is for the top value and is not coming the button where it is presssed
        e.preventDefault();
        const finalBookId = imageIdFromButton || `${bookId}_${index + 1}`;

        setSelectedImage(prevSelImages => ({
            ...prevSelImages,
            [index]: { link, bookId: finalBookId },
        }));

            // send selected image to the backend
        try{ const idToken = await user.getIdToken();
            const response = await fetch(
            `${process.env.REACT_APP_BACKEND}/user/saveSelectedImageNumber`,
            {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                Authorization: idToken,
                },
                body: JSON.stringify({
                bookId: bookId, 
                imageId: finalBookId
                }),
            }
            );
            if (!response.ok){
                console.log("selected image not sent to backend")
            }
        } catch(error){
            console.log("error",error)
            } 

        setIndexList(prevIndexList => {
            const updatedIndexList = new Set(prevIndexList);
            updatedIndexList.delete(index);
            return updatedIndexList;
        });
    }

    async function handleBuildPdf(e) {
        e.preventDefault();
        setBuildBook(true);
        setBuildingBook(true);
    //    const imageNumArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] 

        try {
            const idToken = await user.getIdToken();
            const selectedImagesArray = Object.values(selectedImage).map(item => item.bookId);
            console.log("selectedImagesArray", selectedImagesArray);
            
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/user/createEnhancedPdf`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: idToken,
                    },
                    body: JSON.stringify({
                        bookId,
                        userQuerySelectedImagesArray: selectedImagesArray,
                        counter,
                        imageNumArray
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to build PDF");
            }

            setBuildingBook(false);
            navigate('/library');
        } catch (error) {
            console.error("Error building PDF:", error);
            setBuildingBook(false);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const idToken = await user.getIdToken();
                
                // Fetch legends and counter with retry logic
                let legendsData;
                for(let attempt = 0; attempt < 10; attempt++) {
                    try {
                        const legendsResponse = await fetch(
                            `${process.env.REACT_APP_BACKEND}/user/getlegends`,
                            {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: idToken,
                                },
                                body: JSON.stringify({
                                    bookId: bookId
                                }),
                            }
                        );
    
                        if (legendsResponse.ok) {
                            legendsData = await legendsResponse.json();
                            break;
                        }
                        
                        if (attempt < 9) {
                            await new Promise(resolve => setTimeout(resolve, 30000));
                        }
                    } catch (error) {
                        if (attempt === 9) throw error;
                        await new Promise(resolve => setTimeout(resolve, 30000));
                    }
                }
    
                if (legendsData) {
                    setLegends(legendsData[3]);
                    setCounter(parseInt(legendsData[4][0]));
                // Convert character count array to dictionary
                const characterCountArray = legendsData[5];
                const countDict = {};
                characterCountArray.forEach(item => {
                    const [character, count] = item.split(':');
                    countDict[character] = parseInt(count);
                });
                setCharacterCounts(countDict);
            }
                // Fetch images
                const imagesIdArray = getFullBookId(imageNumArray, bookId);
                console.log("bookId-enhancedbookEdit", imagesIdArray)
                const fetchedImages = [];
                for (let i = 1; i <= 10; i++)  {
                    console.log("bookidnum-enhancedbookEdit", bookId[i-1 ]);
                    const response = await fetch(
                        `${process.env.REACT_APP_BACKEND}/user/imageOutput`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: idToken,
                            },
                            body: JSON.stringify({
                                bookId: `${imagesIdArray[i - 1]}`,
                               // bookId: `${bookId}_${i}`,
                            }),
                        }
                    );

                    if (response.ok) {
                        const blob = await response.blob();
                        const url = URL.createObjectURL(blob);
                        fetchedImages.push(url);
                    } else if (response.status === 404) {
                        console.warn(`Image ${i} not ready yet. Retrying in 30 seconds...`);
                        await new Promise(resolve => setTimeout(resolve, 30000));
                        i--;
                        continue;
                    } else {
                        throw new Error(`Failed to fetch image ${i}`);
                    }
                }

                setImages(fetchedImages);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [bookId, user, paragraphs.length]);

    // Add the cleanup useEffect here, before the return statement
    useEffect(() => {
        return () => {
            images.forEach(url => {
                if (typeof url === 'string' && url.startsWith('blob:')) {
                    URL.revokeObjectURL(url);
                }
            });
            Object.values(reDidImages).forEach(imageArray => {
                imageArray.forEach(image => {
                    if (image.url.startsWith('blob:')) {
                        URL.revokeObjectURL(image.url);
                    }
                });
            });
        };
    }, [images, reDidImages]);

    return (
        <div>
            <PageNav className="absolute inset-x-0 top-0 z-50" />
            {/* Floating Character Guide */}
            {activeTextArea !== null && (
                <div className="fixed right-8 top-1/4 w-64 bg-blue-50 p-4 rounded-lg shadow-lg">
                    <p className="text-sm font-medium text-gray-700 mb-2">Character Names Guide</p>
                    <p className="text-sm text-gray-600 mb-2">Please use these exact names:</p>
                    <ul className="text-sm text-gray-600 list-disc pl-4">
                        {Object.keys(characterCounts).map(name => (
                            <li key={name}>{name}</li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Loading Overlay */}
            {(isRegenerating || loading || buildingBook) && (
                <div className="fixed inset-0 flex flex-col items-center justify-center bg-opacity-50 bg-gray-800 z-50">
                    <Hourglass
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="hourglass-loading"
                        colors={['#306cce', '#72a1ed']}
                    />
                    <p className="mt-4 text-white text-lg text-center">
                        {isRegenerating ? "Regenerating image..." :
                            buildingBook ? "Building your book..." :
                                "Loading your story..."}
                    </p>
                </div>
            )}
    
            {/* Main Content */}
            <div className="mx-auto bg-white px-6 py-10 lg:px-8">
                <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          {/*      <div className="mx-auto text-base font-semibold leading-7 text-gray-900">{`Number of image regenerations left: ${
              20 - counter
            }`}</div> */}
                    {storyTitle && (
                        <p className="mt-16 text-2xl font-bold tracking-tight text-gray-900 text-center">
                            {storyTitle}
                        </p>
                    )}
    
                    <div className="mx-auto text-base font-semibold leading-7 text-gray-900 mt-4">
                        {`Number of image regenerations left: ${20 - counter}`}
                    </div>
    
                    {combinedData.map((item) => (
                        <div className="mt-10 max-w-2xl" key={item.imageIndex}>
                            <p className="font-medium text-gray-700 text-justify">
                                {item.story}
                            </p>
    
                            {item.isSelected ? (
                                <div className="text-center">
                                    <img
                                        className="mx-auto mt-4 mb-4"
                                        src={item.selectedImage.link}
                                        alt={`Selected Image ${item.imageIndex}`}
                                        width="400"
                                        height="400"
                                    />
                                    <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">
                                            Image Description:
                                        </label>
                                        <p>{item.legend}</p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="text-center">
                                        {item.images.length > 0 && (
                                            <img
                                                className="mx-auto mt-4 mb-4"
                                                src={item.images[0].url}
                                                alt={`Generated Image ${item.imageIndex}`}
                                                width="400"
                                                height="400"
                                            />
                                        )}
                                        <div className="mt-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Image Description:
                                            </label>
                                            <textarea
                                                value={item.legend}
                                                onChange={(e) => {
                                                    const newLegends = [...legends];
                                                    newLegends[item.imageIndex] = e.target.value;
                                                    setLegends(newLegends);
                                                }}
                                                onFocus={() => setActiveTextArea(item.imageIndex)}
                                                onBlur={() => setActiveTextArea(null)}
                                                className="w-full min-h-[100px] p-2 border border-gray-300 rounded-md"
                                                placeholder="Edit image description here..."
                                            />
                                        {/*}    <textarea
                                                value={item.legend}
                                                onChange={(e) => {
                                                    const newLegends = [...legends];
                                                    newLegends[item.imageIndex] = e.target.value;
                                                    setLegends(newLegends);
                                                }}
                                                className="w-full min-h-[100px] p-2 border border-gray-300 rounded-md"
                                                placeholder="Edit image description here..."
                                            /> */}
                                        </div>
                                       {/*} <div className="w-64 bg-blue-50 p-4 rounded-lg">
                                            <p className="text-sm font-medium text-gray-700 mb-2">Character Names Guide</p>
                                            <p className="text-sm text-gray-600 mb-2">Please use these exact names when editing the description:</p>
                                            <ul className="text-sm text-gray-600 list-disc pl-4">
                                                {Object.keys(characterCounts).map(name => (
                                                    <li key={name}>{name}</li>
                                                ))}
                                            </ul>
                                        </div> */}
                                        <button
                                            type="button"
                                            className="mt-4 inline-flex items-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-black"
                                            onClick={(e) => handleReDoImage(e, item.imageIndex)}
                                        >
                                            Redo Image
                                        </button>
                                        <button
                                             type="button"
                                            className="inline-flex items-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-black"
                                            onClick={(e) => handleSelectedImage(e, item.imageIndex, item.images[0].url)}
                                            >
                                            Select
                                        </button>
                                    </div>
    
                                    {item.hasRedoneImages && (
                                        <div className="mt-8">
                                            <p className="font-medium text-gray-900 mb-4">Redone Images:</p>
                                            {item.images.slice(1).map((redoneImage, redidIndex) => (
                                                <div key={redidIndex} className="mt-6 text-center">
                                                    <img
                                                        className="mx-auto mt-4 mb-4"
                                                        src={redoneImage.url}
                                                        alt={`Redone Image ${redidIndex}`}
                                                        width="400"
                                                        height="400"
                                                    />
                                                    <div className="mt-4 flex justify-center gap-4">
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-black"
                                                            onClick={(e) => handleReDoImage(e, item.imageIndex)}
                                                        >
                                                            Redo Again
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center rounded-md bg-indigo-300 px-3 py-2 text-sm font-semibold text-black"
                                                            onClick={(e) => handleSelectedImage(e, item.imageIndex, redoneImage.url, redoneImage.bookId)}
                                                        >
                                                            Select
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
    
                {/* Action Buttons */}
                <div className="mt-8 flex justify-center w-full mt-8">
                    {indexList.size === 0 && !buildBook && (
                        
                        <button
                            type="button"
                            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                         //   className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                            onClick={handleBuildPdf}
                        >
                            Build my book!
                        </button>
                    )}
                    
                </div>
            </div>
    
              {imageGenAlert && (
        <ImageGenAlert
          onConfirm={() => setImageGenAlert(false)} // Just close the dialog on confirm
        />
      )}
      {alertMessage && (
      <AlertMessage
        message={alertMessage.message}
        type={alertMessage.type}
        onClose={() => setAlertMessage(null)}
      />
    )}
        </div>
    );
}

